import React, { useMemo } from 'react';

import { LookupQuestion } from './types';
import {
  AssessmentResult,
  useGetAssessmentQuestionLookupDetails,
  LookupDetails,
} from 'src/api';
import Select, { SelectItem } from 'src/components/Select';

interface Props {
  assessmentId: string;
  questionId: string;
  question: LookupQuestion;
  result?: AssessmentResult;
  dependingQuestions: string[];
  onChange: (value: string) => void;
  resultIndex?: number;
  filterOnQuestion?: LookupQuestion;
  filterOnResult?: AssessmentResult;
}

const lookupColumnMap: Record<string, keyof LookupDetails> = {
  model_year: 'modelYear' as keyof LookupDetails,
  make: 'make' as keyof LookupDetails,
  model: 'model',
  brand_name: 'brandName' as keyof LookupDetails,
  type: 'type' as keyof LookupDetails,
};

const AssessmentFormSingleSelectLookup: React.FC<Props> = ({
  assessmentId,
  question,
  questionId,
  onChange,
  result,
  resultIndex,
  filterOnQuestion,
  filterOnResult,
}) => {
  const resultValue = result?.values.find((r) => r.index === resultIndex);
  const filterOnResultValue = filterOnResult?.values.find(
    (f) => f.index === resultIndex
  );

  const { data } = useGetAssessmentQuestionLookupDetails({
    assessmentId,
    questionId,
    lookupTable: question.lookupTable,
    orderBy: question.lookupColumn,
    lookupColumn: filterOnQuestion?.lookupColumn,
    lookupValue: filterOnResultValue?.value,
  });

  const name = question.name + resultIndex;

  const fieldProps = {
    ['data-id']: name,
    name,
    label: question.display,
    value: resultValue?.value ?? '',
    disabled: false,
    onChange,
  };

  const items = useMemo(() => {
    const _data = data?.data ?? [];
    const itemValues = _data.map((item) =>
      item[lookupColumnMap[question.lookupColumn]]?.toString()
    );
    const set = new Set(itemValues);
    const arr = Array.from(set);

    return arr.sort();
  }, [data, question.lookupColumn]);

  return (
    <div>
      <Select
        items={items.map<SelectItem>((option) => ({
          label: option,
          value: option,
        }))}
        placeholder='Choose an option...'
        {...fieldProps}
      />
    </div>
  );
};

export default AssessmentFormSingleSelectLookup;
