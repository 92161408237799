import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AssessmentFormSectionContent } from './types';
import {
  AssessmentResult,
  AssessmentTemplateContentQuestion,
  AssessmentTemplateContentSectionSubSection,
} from 'src/api';

export type AssessmentFormSubSectionContentQuestion =
  AssessmentTemplateContentQuestion & {
    dependingQuestions: string[];
    filteringQuestions: string[];
    filterOnQuestionId?: string;
  };

export interface AssessmentFormSubSectionContent {
  header: string;
  order: number;
  description: string;
  shouldPrevSection: boolean;
  prevSubSection?: string;
  nextSubSection?: string;
  shouldNextSection: boolean;
  nextButtonLabel?: string;
  resultIndex?: number;
  overrideNextButtonAction?: () => void;
  questions: AssessmentFormSubSectionContentQuestion[];
  coreDataFields: string[];
  sectionType?: string;
}

export type AssessmentFormSubSectionContentByName = Record<
  string,
  AssessmentFormSubSectionContent
>;

const resolveQuestionDependencies = (
  subsections: AssessmentTemplateContentSectionSubSection[],
  questions: AssessmentTemplateContentQuestion[],
  results: Record<string, AssessmentResult>
): AssessmentFormSubSectionContentQuestion[] => {
  return questions.reduce<AssessmentFormSubSectionContentQuestion[]>((a, c) => {
    const dependsOnValues = Array.isArray(c.depends_on_value)
      ? c.depends_on_value
      : c.depends_on_value
        ? [c.depends_on_value]
        : [];

    if (c.depends_on && dependsOnValues.some(Boolean)) {
      const dependsOnQuestion = questions.find(
        (q) => q.question_order === c.depends_on
      );

      if (dependsOnQuestion) {
        const result = results[dependsOnQuestion.question_id];
        const hasValue =
          dependsOnValues.includes(result?.value) ||
          result?.values.some((r) => dependsOnValues.includes(r.value));

        if (!hasValue) {
          return a;
        }
      }
    }

    const dependingQuestions: string[] = [];
    const filteringQuestions: string[] = [];

    dependingQuestions.push(
      ...subsections.reduce((acc, s) => {
        if (s.depends_on === c.question_id) {
          const questionIds = s.questions?.map((q) => q.question_id) ?? [];

          acc.push(...questionIds);
        }

        return acc;
      }, [] as string[])
    );

    questions.forEach((q) => {
      if (q.depends_on === c.question_order) {
        dependingQuestions.push(q.question_id);
      }
      if (q.filter_on_value === c.question_order) {
        filteringQuestions.push(q.question_id);
      }
    });

    const filterOnQuestion = questions.find(
      (q) => q.question_order === c.filter_on_value
    );

    a.push({
      ...c,
      dependingQuestions,
      filteringQuestions,
      filterOnQuestionId: filterOnQuestion?.question_id,
    });

    return a;
  }, []);
};

export const useAssessmentFormSubSections = (
  section: AssessmentFormSectionContent,
  results: Record<string, AssessmentResult>
) => {
  const { subsectionNames, subsections } = useMemo(
    () =>
      section.subsections.reduce(
        (a, c, idx, arr) => {
          const getKey = (
            item: AssessmentTemplateContentSectionSubSection
          ): string => item.section_order + item.header;

          const nextSubSection =
            idx < arr.length - 1 ? getKey(arr[idx + 1]) : undefined;
          let prevSubSection = idx > 0 ? getKey(arr[idx - 1]) : '0cover';

          const questions = resolveQuestionDependencies(
            section.subsections,
            c.questions ?? [],
            results
          );

          const key = getKey(c);

          if (c.repeats_on) {
            const repeatsOnResult = results[c.repeats_on];
            const repeatValue = +repeatsOnResult?.value;

            if (!isNaN(repeatValue)) {
              for (let i = 0; i < repeatValue; i++) {
                const repeatedKey = `${key}${i}`;
                const repeatedNextSubSection =
                  i + 1 < repeatValue ? `${key}${i + 1}` : nextSubSection;
                const repeatedPrevSubSection =
                  i > 0 ? `${key}${i - 1}` : prevSubSection;

                if (i === 0 && prevSubSection) {
                  a.subsections[prevSubSection].nextSubSection = repeatedKey;
                }

                a.subsectionNames.push(repeatedKey);

                a.subsections[repeatedKey] = {
                  description: c.description.replace(
                    '{index}',
                    (i + 1).toString()
                  ),
                  header: c.header,
                  order: c.section_order,
                  questions,
                  coreDataFields: c.core_data_fields ?? [],
                  sectionType: c.section_type,
                  nextSubSection: repeatedNextSubSection,
                  prevSubSection: repeatedPrevSubSection,
                  shouldNextSection: !repeatedNextSubSection,
                  shouldPrevSection: !repeatedPrevSubSection,
                  nextButtonLabel: section.nextButtonLabel,
                  overrideNextButtonAction: section.overrideNextButtonAction,
                  resultIndex: i,
                };
              }

              if (repeatValue === 0) {
                const prevKey = getKey(arr[idx - 1]);

                a.subsections[prevKey].nextSubSection = nextSubSection;
              }
            }
          } else {
            if (idx > 0) {
              const prevItem = arr[idx - 1];
              const prevKey = getKey(arr[idx - 1]);

              if (prevItem?.repeats_on) {
                const prevRepeatsOnResult = results[prevItem.repeats_on];
                const prevRepeatValue = +prevRepeatsOnResult?.value;

                if (!isNaN(prevRepeatValue)) {
                  const repeatedPrevSubSection = `${prevKey}${
                    prevRepeatValue - 1
                  }`;

                  prevSubSection = getKey(arr[idx - 2]);
                  if (prevRepeatValue > 0) {
                    a.subsections[repeatedPrevSubSection].nextSubSection = key;
                    prevSubSection = repeatedPrevSubSection;
                  }
                }
              }
            }

            a.subsectionNames.push(key);
            a.subsections[key] = {
              description: c.description,
              header: c.header,
              order: c.section_order,
              questions,
              coreDataFields: c.core_data_fields ?? [],
              sectionType: c.section_type,
              nextSubSection,
              prevSubSection,
              shouldNextSection: !nextSubSection,
              shouldPrevSection: !prevSubSection,
              nextButtonLabel: section.nextButtonLabel,
              overrideNextButtonAction: section.overrideNextButtonAction,
            };
          }

          return a;
        },
        {
          subsectionNames: ['0cover'] as string[],
          subsections: {
            '0cover': {
              description: section.cover.body,
              header: 'cover',
              questions: [],
              order: 0,
              shouldNextSection: !!section.overrideNextButtonAction,
              shouldPrevSection: false,
              nextButtonLabel: section.nextButtonLabel,
              overrideNextButtonAction: section.overrideNextButtonAction,
              nextSubSection: `${section.subsections[0]?.section_order}${section.subsections[0]?.header}`,
              coreDataFields: [],
            },
          } as AssessmentFormSubSectionContentByName,
        }
      ),
    [section, results]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentSubsection, setCurrentSubsection] = useState<
    string | undefined
  >(subsectionNames[0]);

  useEffect(() => {
    const currentSubsectionSearchParam = searchParams.get('currentSubsection');

    if (currentSubsectionSearchParam) {
      setCurrentSubsection(currentSubsectionSearchParam);
      setSearchParams((current) => {
        current.delete('currentSubsection');

        return current;
      });
    }
  }, [searchParams, setCurrentSubsection, setSearchParams]);

  return {
    subsectionNames,
    subsections,
    currentSubsection,
    setCurrentSubsection: (str: string) => setCurrentSubsection(str),
    onPreviousSubsection: () => {
      if (!currentSubsection) return;

      const subsection = subsections[currentSubsection];

      if (subsection.prevSubSection) {
        setCurrentSubsection(subsection.prevSubSection);
      }
    },
    onNextSubsection: () => {
      if (!currentSubsection) return;

      const subsection = subsections[currentSubsection];

      if (subsection.nextSubSection) {
        setCurrentSubsection(subsection.nextSubSection);
      }
    },
  };
};
