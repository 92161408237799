import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import AssessmentFormNavigationIcon from './AssessmentFormNavigationIcon';
import AssessmentFormSection from './AssessmentFormSection';
import { useAssessmentFormSections } from './hooks/useAssessmentFormSections';
import FullPageLayout from 'src/components/Layout/FullPageLayout';
import Select, { SelectItem } from 'src/components/Select';
import AssessmentFormHeader from 'src/pages/Assessment/components/AssessmentFormHeader';

const AssessmentForm = () => {
  const {
    currentSection,
    onNextSection,
    onPreviousSection,
    sectionNames,
    sections,
    setCurrentSection,
  } = useAssessmentFormSections();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectItems = useMemo(
    () =>
      sectionNames.map<SelectItem>((step) => ({
        label: `${sections[step.id].idx} ${sections[step.id].title}`,
        value: step.id.toString(),
        initialElement: <AssessmentFormNavigationIcon status={step.status} />,
      })),
    [sectionNames, sections]
  );

  useEffect(() => {
    const currentSectionSearchParam = searchParams.get('currentSection');

    if (currentSectionSearchParam) {
      setCurrentSection(currentSectionSearchParam);
      setSearchParams((current) => {
        current.delete('currentSection');

        return current;
      });
    }
  }, [searchParams, setCurrentSection, setSearchParams]);

  return (
    <FullPageLayout className='mx-auto bg-white lg:flex lg:min-h-dvh lg:flex-col'>
      {/* Heading */}
      <div className='sticky left-0 top-0 z-10 flex flex-col bg-white'>
        <AssessmentFormHeader
          progress={
            sectionNames.reduce((a, c) => a + c.percentage, 0) /
            sectionNames.length
          }
        />

        {/* Navigation */}
        <div className='p-4'>
          <Select
            data-id='section-select'
            name='section-select'
            key={`${currentSection}-select`}
            onChange={(value) => {
              setCurrentSection(value);
            }}
            value={currentSection!}
            items={selectItems}
          />
        </div>
      </div>

      {/* Content */}
      <div className='flex flex-grow flex-col'>
        {/* FormStep */}
        {!!currentSection && !!sections[currentSection] && (
          <AssessmentFormSection
            key={sections[currentSection].title}
            currentSection={sections[currentSection]}
            onPreviousSection={onPreviousSection}
            onNextSection={onNextSection}
            canNextSection={sections[currentSection].canNextSection}
            canPrevSection={sections[currentSection].canPrevSection}
          />
        )}
      </div>
    </FullPageLayout>
  );
};

export default AssessmentForm;
